<template>
  <div class="home">
    <HeaderCommin />
    <HomeCommin 
      :machineLists="machineLists"
      :itemsPerPage="itemsPerPage"
      :totalCount="totalCount"
      :pageHandleCurrentChange="pageHandleCurrentChange"
      :resetArrayDatas="resetArrayDatas"
      :onPullRefresh="onPullRefresh"
      :onMoreRefresh="onMoreRefresh" />
  </div>
</template>

<script>
// @ is an alias to /src
import HeaderCommin from '@/components/HeaderCommin.vue';
import HomeCommin from '@/components/Home/HomeCommin.vue';
import { getCurrentUserAllMachine } from '@/functions/apis-pages/machineMerchant';

export default {
  name: 'HomeView',
  components: {
    HeaderCommin,
    HomeCommin
  },
  data() {
    return {
      machineLists: [],
      itemsPerPage: 5,
      totalCount: 0,
    };
  },
  created() {
  },
  methods: {
    /**
     * 加载更多
     */
    onMoreRefresh(nextPage, callback) {
      this.pageHandleCurrentChange(nextPage, callback);
    },
    onPullRefresh(callback) {
      const that = this;
      that.getListDatas().then(() => {
        callback.complete();
      });
    },
    resetArrayDatas(arrs) {
      this.machineLists = arrs;
    },
    getListDatas(postDatas = {}) {
      const that = this;
      return new Promise((resolve) => {
        getCurrentUserAllMachine(Object.assign({}, {
          itemsPerPage: that.itemsPerPage
        }, postDatas)).then((datas) => {
          that.machineLists = that.machineLists.concat(datas.data);
          that.totalCount = datas.totalCount;
          resolve(datas);
        });
      });
    },
    pageHandleCurrentChange(val, options) {
      this.getListDatas({
        currentPage: val
      }).then((r) => {
        options.complete(r);
      });
    }
  }
}
</script>

<style scoped>
.home {
  text-align: center;
}
</style>
