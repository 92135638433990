import { createRouter, createWebHistory } from 'vue-router';
import { isLogin } from '../functions/apis-pages/login.js';
import HomeView from '../views/HomeView.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      requireAuth: true // 添加该字段，表示进入这个路由是需要登录的
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      requireAuth: false  // 登录以后，不能访问该页面
    },
    component: () => import('../views/LoginView.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});
router.beforeEach((to, from, next) => {
  if(
    typeof to == 'object' && null != to &&
    typeof to.meta == 'object' && null != to.meta &&
    typeof to.meta.requireAuth == 'boolean'
  ) {
    if (to.meta.requireAuth && !isLogin()) {
      // 必须登录, 跳转到登录
      next('/login');
      return;
    } else if (!to.meta.requireAuth && isLogin()) {
      // 登录以后，不能访问该页面
      next('/');
      return;
    }
  }
  next();
  return;
});
export default router;
