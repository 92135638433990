<template>
  <div :class="['containerBody', pageAdditionalClass]">
    <router-view />
  </div>
</template>

<style>
</style>
<script>
import "@/assets/less/common.less";
export default {
  data() {
    return {
      pageAdditionalClass: "",
    };
  },
  created() {
    this.checkDeviceInfo();
  },
  mounted() {},
  methods: {
    /**
     * 检查设备信息
     */
    checkDeviceInfo() {
      let deviceClass = "";
      if (this.$device.mobile) {
        deviceClass += " " + "mobileBody";
      } else {
        deviceClass += " " + "pcBody";
      }
      if (this.$device.iphone) {
        deviceClass += " " + "iphoneBody";
      } else if (this.$device.android) {
        deviceClass += " " + "androidBody";
      }
      if (this.$device.wechat) {
        deviceClass += " " + "wechatBody";
      } else if (this.$device.wechatMiniApp) {
        deviceClass += " " + "wechatAppBody";
      }
      if (this.$device.iphoneX) {
        deviceClass += " " + "iphoneXBody";
      }
      deviceClass = deviceClass.trim();
      this.pageAdditionalClass = deviceClass;
    },
  },
};
</script>
