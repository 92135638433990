import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createDeviceDetector } from 'next-vue-device-detector'
import { VueCookieNext } from 'vue-cookie-next'
import i18n from './i18n'

// set default config
VueCookieNext.config({ expire: '7d' })

const device = createDeviceDetector()
createApp(App).use(i18n).use(device).use(VueCookieNext).use(router).mount('#app')
