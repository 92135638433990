<template>
  <van-row justify="center" class="headerBox">
    <van-col span="12">
      <van-image
        class="headerLogo"
        fit="cover"
        :src="require('../assets/images/logo-common.png')"
      />
    </van-col>
  </van-row>
</template>

<script>
import { Col, Row, Image } from 'vant';
import "@/assets/less/headerCommin.less";

export default {
  name: 'HeaderCommin',
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Image.name]: Image
  },
}
</script>