import { Toast } from 'vant';
import { getCurrentServerApiDatas } from "../string/getCurrentServerApiKeys";

export function createOrder(opTypeName = '', machineCode = '', options = {}) {
    if (typeof options != 'object' || null == options) options = {};
    let productId = -2;
    if (opTypeName == 'Masks') {
        productId = -1;
    }
    return new Promise((resolve) => {
        getCurrentServerApiDatas('createOrderByCarts', {
            data: {
                isFromBuyMachine: 1,
                choiceAddressId: 0,
                machineBuyProductLists: [{
                    product_id: productId,
                    qty: 1
                }],
                machineCode: machineCode,
                apiVersion: 103,
                isSelfMachine: 1
            }, 
            success: (r) => {
                console .log('rrrrrrrrrr1', r);
                resolve({
                    isTrue: true,
                    data: r
                });
            },
            fail: (code, msg, r) => {
                Toast.fail(msg);
                console .log('rrrrrrrrrr2', code, msg, r);
                resolve({
                    isTrue: false,
                    data: r
                });
            }
        });
    });
}