import { magento } from '../apis';
import { getObjectValue } from './getObjectValue';
import MD5 from './MD5';
import { v4 as uuidv4 } from 'uuid';

/**
 * 请求服务器加密key
 */
export function getCurrentServerApiKeys(options = {}) {
    if (typeof options != 'object' || null == options) options = {};
    if (typeof options.success != 'function') options.success = () => {};
    if (typeof options.fail != 'function') options.fail = () => {};

    const userKey = uuidv4();
    if (typeof userKey != 'string' || userKey.length < 8) {
        options.fail(-1);
        return false;
    }
    magento.guest.
        getCurrentServerDate()
        .then(r => {
            const dateNow = getObjectValue(r, 'data', 'date', '');
            if (typeof dateNow != 'string' || dateNow.length < 19) {
                options.fail(-2);
                return false;
            }
            const userToken = MD5(MD5(MD5(
                'td-'+dateNow.substring(0, 16)+
                '-'+userKey+
                '-'+userKey.substring(1,2).charCodeAt()
            )));
            options.success({
                dateNow: dateNow,
                apiPost: {
                    userKey: userKey,
                    userToken: userToken
                }
            });
        }).catch(r => {
            options.fail(r);
        });
}



/**
 * 得到API的数据
 */
export function getCurrentServerApiDatas(apiName, options = {}) {
    if (typeof options != 'object' || null == options) options = {};
    if (typeof options.success != 'function') options.success = () => {};
    if (typeof options.fail != 'function') options.fail = () => {};
    if (typeof options.data != 'object' || null == options.data) options.data = {};

    const apiFunction = getObjectValue(magento, 'guest', ''+apiName, null);
    if (null == apiFunction || typeof apiFunction == 'undefined') {
        options.fail(-4, "");
        return;
    }

    getCurrentServerApiKeys({
        success: (keysContent) => {
            apiFunction(Object.assign({}, options.data, keysContent.apiPost)).then(r => {
                const code = getObjectValue(r, 'data', 'code', -1);
                const msg = getObjectValue(r, 'data', 'msg', '');
                if (!('1' === code || 1 === code)) {
                    options.fail(-3, msg, r);
                    return;
                }
                options.success(
                    getObjectValue(r, 'data', {})
                );
            }).catch(r => {
                options.fail(-2, "", r);
            });
        },
        fail: (e) => {
            options.fail(-1, "", e);
        }
    });
}