/**
 * 得到组件之间传递数据的原本值 (只针对数组)
 */
export function getParamArray(proxyArrs) {
    if (typeof proxyArrs != 'object' || null == proxyArrs) return;
    try {
        proxyArrs = JSON.parse(JSON.stringify(proxyArrs))
    } catch (e) {
        console .log('err', e)
    }
    if (typeof proxyArrs != 'object' || null == proxyArrs) {
        return [];
    }
    return proxyArrs;
}

/**
 * 修改数组其中的一项
 */
export function updateArrayListDataByField(
    arrs, whereField, whereValue, updateField, updateValue
) {
    if (
        typeof arrs != 'object' || null == arrs || arrs.length <= 0
    ) {
        return [];
    }
    if (
        typeof whereField != 'string' || '' == whereField.trim() || 
        typeof updateField != 'string' || '' == updateField.trim() ||
        typeof whereValue == 'undefined' || typeof updateValue == 'undefined'
    ) {
        return arrs;
    }
    for (const key in arrs) {
        if (!Object.hasOwnProperty.call(arrs, key)) {
            continue;
        }
        const element = arrs[key];
        if (
            (typeof element == 'object' && null != element) && 
            typeof element[whereField] != 'undefined' && 
            element[whereField] == whereValue
        ) {
            arrs[key][updateField] = updateValue;
        }
    }
    return arrs;
}