/**
 * 运行运行函数
 * 
 * @param int i                最大的运行次数
 * @param object options
 * @returns 
 */
export function runLoop(i, options = {}) {
    if (typeof i != 'number' || i < 1) {
        return false;
    }
    if (typeof options != 'object' || null == options) options = {};
    if (typeof options.time != 'number') options.time = 1000;
    if (typeof options.onRun != 'function') options.onRun = () => {};

    i = (i+1) - 1;
    if (i <= 1) {
        options.onRun(i);
        return;
    }
    options.onRun(i);
    setTimeout(function () {
        runLoop(i - 1, options);
    }, options.time);
}