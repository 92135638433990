import { getCurrentServerApiDatas } from "../string/getCurrentServerApiKeys";
import { getObjectValue } from "../string/getObjectValue";

/**
 * 判断是否登录
 * 
 * @returns bool
 */
 export function getMachineStatusInfoByMachineCode(postDatas = {}) {
    return new Promise((resolve) => {
        getCurrentServerApiDatas('getMachineStatusInfoByMachineCode', {
            data: postDatas,
            success: (r) => {
                resolve({
                    machineStatus: getObjectValue(r, 'machineStatus', [])
                });
            },
            fail: (code, msg, r) => {
                resolve({
                    machineStatus: []
                });
                console .log('rrrrr2', code, msg, r)
            }
        });
    });
}

/**
 * 判断是否登录
 * 
 * @returns bool
 */
export function getCurrentUserAllMachine(postDatas = {}) {
    return new Promise((resolve) => {
        getCurrentServerApiDatas('getMerchantAllMachineDetails', {
            data: postDatas,
            success: (r) => {
                resolve({
                    data: getObjectValue(r, 'data', []),
                    totalCount: parseInt(getObjectValue(r, 'totalCount', 0))
                });
            },
            fail: (code, msg, r) => {
                resolve({
                    data: [],
                    totalCount: 0
                });
                console .log('rrrrr2', code, msg, r)
            }
        });
    });
}

/**
 * 更新机器上的名字
 */
export function updateCurrentUserAllMachineName(opId = 0, extInfo = {}) {
    return opMachineRelevantInformation('update-name', opId, extInfo);
}

/**
 * 操作机器相关信息
 * 
 * @param string opName     操作名称     
 *                             update-name =>  修改机器名称   
 * @param int opId          操作的主键id
 * @returns 
 */
export function opMachineRelevantInformation(opName = '', opId = 0, extInfo = {}) {
    if (typeof extInfo != 'object' || null == extInfo) extInfo = {};
    return new Promise((resolve) => {
        getCurrentServerApiDatas('opMachineRelevantInformation', {
            data: Object.assign({}, extInfo, {
                op: opName,
                id: opId
            }),
            success: (r) => {
                resolve({
                    isTrue: true,
                    data: r
                });
            },
            fail: (code, msg, r) => {
                console .log('rrrrr2', code, msg, r)
                resolve({
                    isTrue: false,
                    data: r
                });
            }
        });
    });
}