<template>
  <van-pull-refresh 
  :pulling-text="$t('pullingText')"
  :loosing-text="$t('loosingText')"
  :loading-text="$t('loadingText')"
  v-model="pullLoading" 
  @refresh="onRefresh">
      <van-list 
        v-model:loading="moreLoading"
        :finished="moreFinished"
        :loading-text="$t('loadingText')"
        :finished-text="$t('finishedText')"
        @load="onLoadRefresh"
        v-if="(typeof machineLists == 'object' && null != machineLists && machineLists.length > 0)">
          <van-row justify="center" align="center" class="machineListsBox"
            v-for="(machineList) in machineLists" :key="machineList.id">
            <van-col span="6" class="t-th machineCode">
              ID: {{machineList.machineCode}}
            </van-col>
            <van-col span="16" class="t-th machineName" :list-id="machineList.id">
              &nbsp;{{machineList.machineName}}
            </van-col>
            <van-col span="2" class="t-th t-th-right">
              <van-icon name="edit" @click="onClickEidtMachineName" />
            </van-col>
            <van-col span="24" class="t-th">
              <van-row justify="center" align="center" 
                  class="machineListsContent"
                  v-if="(typeof machineList.portsDatas == 'object' && null != machineList.portsDatas && machineList.portsDatas.length > 0)">
                  <van-col span="6">{{$t('module')}}</van-col>
                  <van-col span="4">{{$t('status')}}</van-col>
                  <van-col span="10">{{$t('detail')}}</van-col>
                  <van-col span="4">{{$t('action')}}</van-col>

                  <template v-for="(portsData, key2) in machineList.portsDatas" 
                            :key="machineList.id+key2">
                    <van-col span="6">{{portsData.name}}</van-col>
                    <van-col span="4" @click="onRefreshMachineStatus(machineList.machineCode)">
                      <van-icon :name="portsData.status == 1 ? 'checked' : 'warning'" />
                    </van-col>
                    <van-col span="10">{{portsData.statusText}}</van-col>
                    <van-col span="4" @click="onClickTestMachinePort"
                            :module-name="portsData.name"
                            :machine-code="machineList.machineCode">
                            <span 
                              class="test-button"
                              :module-name="portsData.name"
                              :machine-code="machineList.machineCode">{{$t('test')}}</span>
                    </van-col>
                  </template>

              </van-row>
            </van-col>
          </van-row>
          
          <!-- <div class="pagination-box">
            <van-pagination
              :prev-text="$t('prevText')"
              :next-text="$t('nextText')"
              :page-count="$t('tatalPageCount')"
              v-model="currentPage"
              :total-items="totalCount"
              :items-per-page="itemsPerPage"
              force-ellipses
              @change="handleCurrentChange"
            />
          </div> -->
      </van-list>
      <van-empty 
        v-if="!(typeof machineLists == 'object' && null != machineLists && machineLists.length > 0)"
        :description="$t('merchantNoMachine')" />
  </van-pull-refresh>
</template>

<script>
import { 
  Col, Row, Image, Icon, Field, CellGroup, Pagination, 
  Toast, Dialog, PullRefresh, List, Empty
} from 'vant';
import "@/assets/less/pages/homeCommin.less";
import $ from "jquery";
import { getMachineStatusInfoByMachineCode, updateCurrentUserAllMachineName } from '@/functions/apis-pages/machineMerchant';
import { 
  getParamArray, updateArrayListDataByField 
} from '@/functions/string/getParamArray';
import { createOrder } from '@/functions/apis-pages/orders';
import { getObjectValue } from '@/functions/string/getObjectValue';
import { runLoop } from '@/functions/string/runLoop';

export default {
  name: 'HomeCommin',
  props: [
    "machineLists", "itemsPerPage", "totalCount", 
    "pageHandleCurrentChange", "resetArrayDatas",
    "onPullRefresh",
    "onMoreRefresh"
  ],
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [Pagination.name]: Pagination,
    [Field.name]: Field,
    [CellGroup.name]: CellGroup,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Empty.name]: Empty
  },
  data() {
    return {
      pullLoading: false,
      moreLoading: false,
      moreFinished: false,
      currentPage: 1
    };
  },
  created() {
    this.onRefresh();
  },
  mounted() {
  },
  setup() {
  },
  methods: {
    /**
     * 加载更多
     */
    onLoadRefresh() {
      const that = this;
      if (typeof that.onMoreRefresh == 'function') {
        if (that.currentPage > 50) {
          that.moreLoading = false;
          that.moreFinished = true;
          return;
        }
        that.onMoreRefresh(that.currentPage + 1, {
          complete: (r) => {
            const data = getObjectValue(r, 'data', []);
            if (typeof data != 'object' || null == data || data.length < 1) {
              that.moreLoading = false;
              that.moreFinished = true;
              return;
            }
            that.moreLoading = false;
            that.currentPage = that.currentPage + 1;
          }
        });
        return;
      }
      that.moreLoading = false;
      that.moreFinished = true;
    },
    /**
     * 下拉刷新的时候
     */
    onRefresh() {
      const that = this;
      that.currentPage = 1;
      if (typeof that.resetArrayDatas == 'function') {
          that.resetArrayDatas([]);
      }
      that.moreLoading = false;
      that.moreFinished = false;
      if (typeof that.onPullRefresh == 'function') {
        that.onPullRefresh({
          complete: () => {
            that.pullLoading = false;
          }
        });
        return;
      }
      that.pullLoading = false;
    },
    handleCurrentChange(val) {
      if (typeof this.pageHandleCurrentChange != 'function') {
        return;
      }
      Toast.loading({
            message: this.$t("loading"),
            forbidClick: true,
            duration: 0
      });
      this.pageHandleCurrentChange(val, {
        complete: () => {
          Toast.clear();
        }
      });
    },
    onClickEidtMachineName(o) {
      const that = this;
      const p = $(o.target).parents('.van-row');
      const ipt = p.find('.machineName');
      ipt.attr('contenteditable', true).focus().off('blur').on('blur', function() {
        $(this).attr('contenteditable', false);
        updateCurrentUserAllMachineName(ipt.attr('list-id'), {
          machineName: ipt.text()
        }).then((data) => {
          if (!data.isTrue) return;
          const newLists = updateArrayListDataByField(
            getParamArray(that.machineLists), 
            'id', ipt.attr('list-id'), 
            'machineName', data.data.machineName
          );
          if (typeof that.resetArrayDatas == 'function') {
            that.resetArrayDatas(newLists);
          }
        });
        return false;
      });
    },

    /**
     * 点击机器端口测试发送口罩、环保袋
     */
    onClickTestMachinePort(o) {
      const that = this;
      const p = $(o.target).parents('.van-row');
      const dMsg = p.find('.machineCode').text() + "    " + 
                    p.find('.machineName').text() + "\n\n" +
                    that.$t('module')+": "+$(o.target).attr('module-name');

      Dialog.confirm({
        title: that.$t('test'),
        cancelButtonText: that.$t('cancel'),
        confirmButtonText: that.$t('confirm'),
        message: dMsg
      })
      .then(() => {
        // on confirm
        createOrder( 
          $(o.target).attr('module-name'), 
          $(o.target).attr('machine-code')
        ).then(() => {
          that.onRefreshMachineStatus(
            $(o.target).attr('machine-code'), 4
          );
        });
      })
      .catch(() => {
        // on cancel
      });
    },

    /**
     * 刷新状态
     */
    onRefreshMachineStatus(machineCode, runCount = 1) {
      const that = this;
      runLoop(runCount, {
        time: 4000,
        onRun: () => {
         getMachineStatusInfoByMachineCode({machineCode}).then((r) => {
            const machineStatus = getObjectValue(r, 'machineStatus', 'portsDatas', []);
            if (
              typeof machineStatus != 'object' || 
              null == machineStatus ||
              machineStatus.length < 1
            ) {
              return;
            }
            const newLists = updateArrayListDataByField(
              getParamArray(that.machineLists), 
              'id', getObjectValue(r, 'machineStatus', 'id', 0), 
              'portsDatas', machineStatus
            );
            if (typeof that.resetArrayDatas == 'function') {
              that.resetArrayDatas(newLists);
            }
         });
        }
      });
    }
  }
}
</script>