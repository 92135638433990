
import { magento } from '../apis';
import { Toast } from 'vant';

/**
 * 得到模板对象
 * 
 * @param object options 
 * @returns 
 */
export function getTplThis(options) {
    if (typeof options != 'object' || null == options) options = {};
    if (typeof options.t != 'function') options.t = () => {};
    if (typeof options.router != 'object' || null == options.router) options.router = {};
    if (typeof options.router.push != 'function') options.router.push = () => {};
    if (typeof options.isShowToast != 'boolean') options.isShowToast = false;
    return options;
}

export function loginNow(email, password, options = {}) {
    options = getTplThis(options);

    if (options.isShowToast) {
        Toast.loading({
            message: options.t("loading"),
            forbidClick: true,
        });
    }
    return new Promise((resolve) => {
        magento.guest
        .login({
            email,
            password,
        })
        .then(token => {
            if (typeof token != 'string') {
                resolve(false);
                return;
            }
            resolve(token);
            return;
        })
        .catch(() => {
            resolve(false);
            return;
        });
    }).then((token) => {
        if (!token) {
            // 失败
            if (options.isShowToast) {
                Toast.fail(options.t("loginFailed"));
            }
            return;
        }
        // 登录成功
        if (options.isShowToast) {
            Toast.success('');
        }
        magento.setCustomerToken(token);
        options.router.push('/');
        return token;
    })
}

/**
 * 判断是否登录
 * 
 * @returns bool
 */
export function isLogin() {
    return magento.isLogin();
}